body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.table {
  overflow: auto;
  display: block;
  table-layout: auto;
}

.container {
  padding-left: 0px;
  padding-right: 0px;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.flex-fill {
  flex: 1 1 auto;
}

#frozen_header_table tbody {
  display: block;
  height: 500px;
  overflow-y: scroll;
}

#frozen_header_table th {
  position: sticky;
  top: 0;
}
